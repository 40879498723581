@for $i from 0 through 5 {
  .h-#{150 + ($i * 150)} {
    height: #{150 + ($i * 150)}px;
  }
}

@for $i from 1 through 1400 {
  .max-height-#{$i} {
    max-height: #{$i}px !important;
  }
}

@for $i from 1 through 1400 {
  .min-height-#{$i} {
    min-height: #{$i}px !important;
  }
}

@for $i from 1 through 1400 {
  @media (min-width: 1350px) {
    .max-height-#{$i}-xl {
      max-height: #{$i}px !important;
    }
  }
}

@for $i from 1 through 1400 {
  @media (min-width: 1550px) {
    .max-height-#{$i}-2xl {
      max-height: #{$i}px !important;
    }
  }
}