@each $color,
$value in $palette {
    .bg-#{$color} {
        background-color: $value  !important;
    }
}

@for $i from 0 through 10 {
    $opacity: $i / 10;
    @each $color, $value in $palette {
        .bg-#{$color}-#{$opacity * 100} {
            background-color: rgba($value, $opacity) !important;
        }
    }
}

@each $color,
$value in $palette {
    .bg-#{$color}-hover:hover {
        background-color: $value  !important;
    }
}