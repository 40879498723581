/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

.trans-menu {
  -webkit-transition: width ease-in-out 0.25s;
  -o-transition: width ease-in-out 0.25s;
  transition: width ease-in-out 0.25s;
}

.trans-menu-entering,
.trans-menu-exiting,
.trans-menu-exited {
  width: 108px;
}

.trans-menu-entered {
  width: 270px;
}

.trans-fade {
  -webkit-transition: opacity 0.25s;
  -o-transition: opacity 0.25s;
  transition: opacity 0.25s;
}

.trans-fade-exiting,
.trans-fade-entering {
  opacity: 0;
  display: block;
}

.trans-fade-entered {
  opacity: 1;
  display: block;
}

.trans-fade-exited {
  opacity: 0;
  display: none;
}

.device-detail-open,
.device-detail-close {
  -webkit-transition: 0.25s;
  -o-transition: 0.25s;
  transition: 0.25s;
}

.device-detail-close {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.device-detail-open {
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
}

.select-detail-open,
.select-detail-close {
  -webkit-transition: 0.25s;
  -o-transition: 0.25s;
  transition: 0.25s;
}

.select-detail-close {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.select-detail-open {
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
}

.select-detail-check {
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
}

.bill-detail-open,
.bill-detail-close {
  -webkit-transition: 0.25s;
  -o-transition: 0.25s;
  transition: 0.25s;
}

.bill-detail-close {
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
}

.bill-detail-open {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.report-detail-open,
.report-detail-close {
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  -o-transition-property: transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -o-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-transition-duration: 150ms;
  -o-transition-duration: 150ms;
  transition-duration: 150ms;
}

.report-detail-close {
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
}

.report-detail-open {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.rotate-45 {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.transition-transform {
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  -o-transition-property: transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -o-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-transition-duration: 150ms;
  -o-transition-duration: 150ms;
  transition-duration: 150ms;
}

.transition-background {
  -webkit-transition-property: all;
  -o-transition-property: all;
  transition-property: all;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -o-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-transition-duration: 1000ms;
  -o-transition-duration: 1000ms;
  transition-duration: 1000ms;
}

.rotate-180 {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.trans-menu > div.dark > div:nth-of-type(2) > a:hover {
  background-color: #ffffff1a;
  border-radius: 8px;
  transition: all 0.25s ease-in-out;
}

.trans-menu > div.light > div:nth-of-type(2) > a:hover {
  background-color: #1414141a;
  border-radius: 8px;
  transition: all 0.25s ease-in-out
}

.trans-menu > div.dark .item-menu:hover {
  background-color: #ffffff1a;
  transition: all 0.25s ease-in-out;
}

.trans-menu > div.light .item-menu:hover {
  background-color: #1414141a;
}

.react-joyride__spotlight {
  width: 275px !important;
  height: 84px !important;
}

.collapse-enter {
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  transition: max-height 1s ease-out, opacity 1s ease-out;
}

.collapse-enter-active {
  max-height: 200px; 
  opacity: 1;
}

.collapse-exit {
  max-height: 200px;
  opacity: 1;
  overflow: hidden;
}

.collapse-exit-active {
  max-height: 0;
  opacity: 0;
  transition: max-height 1s ease-in, opacity 1s ease-in;
}

.text-expand {
  max-width: 0px;
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
  transition: max-width 0.25s ease-in-out, padding 0.25s ease-in-out;
}

.text-expand-active {
  max-width: 100%;
}

.container-hover {
  transition: padding 0.25s ease-in-out, margin 0.25s ease-in-out;
}

.dynamic-container-hover {
  color: transparent;
  transition: height 0.25s ease-in-out, width 0.25s ease-in-out, gap 0.25s ease-in-out;
}

.collapse-enter {
  max-height: 0;
  opacity: 0;
}

.collapse-enter-active {
  max-height: 150px;
  opacity: 1;
  transition: max-height 0.25s ease-in-out, opacity 0.25s ease-in-out;
}

.collapse-exit {
  max-height: 150px;
  opacity: 1;
}

.collapse-exit-active {
  max-height: 0;
  opacity: 0;
  transition: max-height 0.25s ease-in-out, opacity 0.25s ease-in-out;
}
