// Fill button
.button-fill-default.light {
    background-color: $gray-950;
}

.button-fill-disabled.light {
    background-color: $alpha-black-6;
}

.button-fill-hover.light {
    background-color: $gray-700; 
}

.button-fill-default.dark {
    background-color: $white; 
}

.button-fill-disabled.dark {
    background-color: $alpha-white-6; 
}

.button-fill-hover.dark {
    background-color:$gray-200  
}

.button-fill-brand.dark,
.button-fill-brand.light {
    background-color: $brand-primary-500; 
}

.button-fill-error.dark,
.button-fill-error.light {
    background-color: $red-500; 
}

.button-fill-red.dark,
.button-fill-red.light {
    background-color: $red-500; 
}

.button-fill-orange.dark,
.button-fill-orange.light {
    background-color: $orange-500; 
}

// Border button
.button-border-default.light {
    border: 1px solid $alpha-white-10; 
}

.button-border-hover.light {
    border: 1px solid $alpha-white-32;
}

.button-border-outline.light {
    border: 1px solid $black;
}

.button-border-default.dark {
    border: 1px solid $alpha-black-10; 
}

.button-border-hover.dark {
    border: 1px solid $alpha-black-32; 
}

.button-border-outline.dark {
    border: 1px solid $white;
}

.button-border-brand.light,
.button-border-brand.dark {
    border: 1px solid $brand-primary-500; 
}

.button-border-error.light,
.button-border-error.dark {
    border: 1px solid $red-500;
}

.button-border-red.light,
.button-border-red.dark {
    border: 1px solid $red-500;
}

.button-border-green.light,
.button-border-green.dark {
    border: 1px solid $brand-primary-500 ;
}

// Content button
.button-content-default.light {
    color: $black; 
}

.button-content-inverse.light {
    color: $white; 
}

.button-content-disabled.light {
    color: $alpha-black-32; 
}

.button-content-brand.light,
.button-content-brand.dark {
    color: $brand-primary-500; 
}

.button-content-error.light,
.button-content-error.dark {
    color: $red-500; 
}

.button-content-equal.light,
.button-content-equal.dark {
    color: $black;
}

.button-content-default.dark {
    color: $white; 
}

.button-content-inverse.dark {
    color: $black; 
}

.button-content-disabled.dark {
    color: $alpha-white-32; 
}

.button-content-brand.dark,
.button-content-brand.light {
    color: $brand-primary-500; 
}

.button-content-error.dark,
.button-content-error.light {
    color: $red-500; 
}
