@mixin content-default-primary-dark {
    color: map-get($palette, "white");
}
@mixin content-default-primary-light {
    color: map-get($palette, "black");
}

@mixin content-color-green-dark {
    color: map-get($palette, "brand-primary-500");
}
@mixin content-color-green-light {
    color: map-get($palette, "brand-primary-600"); 
}

// light theme
.content-default-primary.light {
    @include content-default-primary-light;
}
.content-default-secondary.light {
    color: map-get($palette, "alpha-black-64"); 
}
.content-default-tertiary.light {
    color: map-get($palette, "alpha-black-32"); 
}
.content-color-green.light{
    @include content-color-green-light;
}

.content-color-blue.light {
    color: map-get($palette, "blue-600");
}

.content-color-red.light {
    color: map-get($palette, "red-600");
}

.content-color-orange.light {
    color: map-get($palette, "orange-600");
}

.content-color-purple.light {
    color: map-get($palette, "purple-600")
}

.content-color-pink.light {
    color: map-get($palette, "pink-600");
}

// Dark theme
.content-default-primary.dark {
    @include content-default-primary-dark;
}
.content-default-secondary.dark {
    color: map-get($palette, "alpha-white-64");
}
.content-default-tertiary.dark {
    color: map-get($palette, "alpha-white-32");
}

.content-color-neutral.light,
.content-color-neutral.dark {
    color:  map-get($palette, "neutral-900"); 
}

.content-color-green.dark {
    @include content-color-green-dark;
}

.content-color-blue.dark {
    color: map-get($palette, "blue-500"); 
}

.content-color-red.dark {
    color: map-get($palette, "red-500"); 
}

.content-color-orange.dark {
    color: map-get($palette, "orange-500");
}

.content-color-purple.dark {
    color: map-get($palette, "purple-500");
}

.content-color-pink.dark {
    color: map-get($palette, "pink-500"); 
}


