/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

#main-container {
  margin-left: 0;
}

@media (min-width: 768px) {
  #main-container {
    margin-left: 108px;
    width: calc(100% - 108px);
  }
}

#notifications-type,
#home-graph-picker,
#home-month-picker {
  -webkit-transition: 0.25s ease-out;
  -o-transition: 0.25s ease-out;
  transition: 0.25s ease-out;
}

#home-graph-container {
  height: 750 px;
}

/* Plus */

.toggle-ui {
  & .rs-btn-toggle {
    background-color: transparent !important;
    border: 2px $green-500 solid !important;
  }

  & .rs-btn-toggle::after {
    background-color: $green-500 !important;
    top: 2px !important;
  }

  & .rs-btn-toggle-checked {
    background-color: $green-500 !important;
  }

  & .rs-btn-toggle.rs-btn-toggle-checked::after {
    background-color: $black !important;
  }
}

/* neu Picker */

.neu-picker-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: $gray-500;
  width: -webkit-fit-content !important;
  width: -moz-fit-content !important;
  width: fit-content !important;
  border-radius: 8px;

  & button {
    background-color: $gray-500;
    color: $neutral-200;
    padding: 2px;
    border-radius: 8px;
    padding: 5px 25px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }
}

.neu-picker-selected {
  background-color: $gray-800 !important;
  color: $green-500 !important;
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  -webkit-transform: scaleX(1.06);
  -ms-transform: scaleX(1.06);
  transform: scaleX(1.06);
}

.neu-picker-separator {
  border: 1px solid $gray-600;
  color: $gray-600;
  border-width: 0.5px;
  opacity: 1;
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;

  &:not([size]) {
    height: 18px;
  }
}

@media (max-width: 768px) {
  .neu-picker-container {
    & button {
      padding: 5px 12px;
      font-size: 14px;
    }

    & i {
      font-size: 12px !important;
    }
  }
}

.neu-services-selected {
  background-color: $green-500 !important;
  color: $neutral-800 !important;
  border: none !important;
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  -webkit-transform: scaleX(1.06);
  -ms-transform: scaleX(1.06);
  transform: scaleX(1.06);
}

/* neu Picker end */

/* services */

.graphs-picker-container,
.services-container {
  &::-webkit-scrollbar-track {
    background-color: transparent !important;
  }
}

/* services end */

/* textArea */
.text-area {
  height: 100px;
  width: -webkit-fill-available !important;
  background-color: $gray-800;
  color: $white;
  border-radius: 8px;
}

.text-area:focus {
  outline: none !important;
  border: 2px solid $green-500;
}

/* textArea end */

/* button Support */

.button-support {
  width: 40px;
  height: 40px;
  -webkit-transition: 0.25s;
  -o-transition: 0.25s;
  transition: 0.25s;
}

.button-icon {
  min-width: 40px;
  min-height: 40px;
  margin-right: 0px;
}

.button-icon-notification {
  position: absolute;
  bottom: 8px;
  right: 8px;
}

.button-support:hover {
  width: 160px;
}

.button-support:hover .button-icon {
  min-width: 0px;
  margin-right: 8px;
}

.button-support:hover .button-icon-notification {
  right: -4px;
}

@-webkit-keyframes changeWidth {
  0% {
    width: 40px;
  }

  50% {
    width: 130px;
  }

  100% {
    width: 40px;
  }
}

@keyframes changeWidth {
  0% {
    width: 40px;
  }

  50% {
    width: 130px;
  }

  100% {
    width: 40px;
  }
}

/* button Support end */

/* modal Tag */

.rs-modal-content {
  background-color: transparent !important;
  border-radius: 24px !important;
  // border: solid 1px $gray-800 !important;
}


/* modal Tag end */

/* table Tag */

.rs-table {
  background-color: $gray-800 !important;
  border-radius: 10px !important;
}

.rs-table-row-header,
.rs-table-cell-content {
  background-color: $gray-800 !important;
}

.rs-table-hover .rs-table-row:not(.rs-table-row-header):hover {
  background: transparent !important;
}

.rs-checkbox label,
.rs-radio label {
  background-color: $blue-500 !important;
}

.table {
  --bs-table-bg: transparent;
}

.table thead th:first-child,
.table tbody td:first-child {
  padding-left: 1rem;
}

.table thead th:first-child,
.table tbody td:last-child {
  padding-right: 1rem;
}

.table thead th,
.table tbody td {
  max-width: 140px !important;
}

// .table>tbody {
//   border-style: none !important;
// }

tbody,
tr {
  border-color: transparent !important;
  /* O cualquier color que prefieras */
  border-style: none !important;
  /* Elimina las líneas del borde */
  border-width: 0 !important;
  /* Asegura que no haya bordes */
}

.table>thead {
  // border: none !important;
  // -webkit-box-shadow: 0 1px 1px $gray-800;
  // box-shadow: 0 1px 1px $gray-800;
  margin-bottom: 0px !important;
}

.table>tbody>*:last-of-type>* {
  border-bottom-color: transparent !important;
}

.table-overflow {
  max-height: 70vh;
  overflow-y: auto;
}

/* table Tag end */

/* calendar end */

.react-calendar__viewContainer>div>div>button[disabled] {
  opacity: 0.4 !important;
}

.react-calendar__month-view__days>button[disabled] {
  opacity: 0.4 !important;
}

/* calendar end */

/* select */

.form-select {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/></svg>");
}

/* select end */

/* input neugets */

#neugets-logo-small {
  height: 25px;
  width: 25px;
  border: 2px solid $white;
}

.rs-input,
rs-input-number {
  background-color: $gray-800 !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  background-color: inherit !important;
  -webkit-text-fill-color: map-get($palette, "gray-400") !important;
}

/* input neugets end */

/* modal */

.rs-modal {
  margin: 30px auto !important;
}

.rs-modal-backdrop.light.in {
  opacity: 1;
}
.rs-modal-backdrop.light {
  background-color: $alpha-black-16 !important;
}

.rs-modal-backdrop.dark.in {
  opacity: 1;
}
.rs-modal-backdrop.dark {
  background-color: $alpha-black-64 !important;
}

.rs-modal-body {
  max-height: none !important;
  margin-top: 0px !important;
  padding-bottom: 0px !important;
}

.rs-modal-content {
  padding: 0px !important;
  border-radius: 24px !important;
}

.rs-modal-header,
.rs-modal-footer,
.rs-drawer-header,
.rs-drawer-footer {
  height: 80px;
}

// .rs-modal-header,
// .rs-drawer-header {
//   border-bottom: 1px solid $gray-600 !important;
// }

// .rs-modal-footer,
// .rs-drawer-footer {
//   border-top: 1px solid $gray-600 !important;
// }

/* end modal */

/* swiper */

.swiper-slide-next,
.swiper-slide-prev>.swiper-slider-container {
  opacity: 0.6 !important;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

/* swiper end */

/* toogle */

.rs-btn-toggle {
  background: $gray-450 !important;
  height: 26px !important;
  border: 1px solid $gray-800;
}

.rs-btn-toggle-checked {
  background: $green-500 !important;
}

.rs-btn-toggle.rs-btn-toggle-checked:after {
  background: $gray-450 !important;
}

/* end toogle */

/* wallet card */
.wallet-card-container {
  height: 120px;
  background: -o-radial-gradient(top left, farthest-corner, $green-500 0, transparent 50%), -o-radial-gradient(left center, farthest-corner, $blue-500 0, transparent 80%), -o-radial-gradient(bottom center, farthest-corner, $blue-500 0, transparent 50%), -o-radial-gradient(bottom right, farthest-corner, $green-500 0, transparent 70%), -o-radial-gradient(top right, farthest-corner, $blue-500 0, transparent 50%) $gray-500;
  background: radial-gradient(farthest-corner at top left, $green-500 0, transparent 50%), radial-gradient(farthest-corner at left center, $blue-500 0, transparent 80%), radial-gradient(farthest-corner at bottom center, $blue-500 0, transparent 50%), radial-gradient(farthest-corner at bottom right, $green-500 0, transparent 70%), radial-gradient(farthest-corner at top right, $blue-500 0, transparent 50%) $gray-500;
}

/* wallet card end */

/* user image */
.container-image {
  width: 100%;
  height: 100%;
}

.image-user {
  opacity: 1;
  -webkit-transition: 0.5s ease;
  -o-transition: 0.5s ease;
  transition: 0.5s ease;
  -o-object-fit: cover;
  object-fit: cover;
}

.middle {
  -webkit-transition: 0.5s ease;
  -o-transition: 0.5s ease;
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.container-image:hover .image-user {
  opacity: 0.2;
}

.container-image:hover .middle {
  opacity: 1;
}

/* user image end */

.modal-plus {
  background-color: rgba($white, 0.95);
  height: 296px;
  line-height: 1.5;
  max-width: 50%;
  z-index: 6;
  position: fixed;
  top: calc(50% - 148px);
}

.banner-energy {
  background-color: rgba($white, 0.95);
  line-height: 1.5;
}

.border-transparent {
  border: 1px solid transparent !important;
}

.bg-header-genius {
  background-color: rgba(124, 124, 124, 1);
  background-blend-mode: overlay;
}

@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
  .modal-plus {
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    background-color: rgba($white, 0.05);
  }

  .banner-energy {
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    background-color: rgba(253, 255, 245, 0.7);
  }

  .bg-header-genius {
    -webkit-backdrop-filter: blur(25px);
    backdrop-filter: blur(25px);
    background-color: rgba(37, 37, 37, 0.9);
  }
}

.rs-tooltip-arrow {
  display: none;
}

.rs-tooltip .rs-tooltip-inner {
  width: "fit-content";
  padding: 2px;
}

.dark .rs-tooltip .rs-tooltip-inner {
  @include border-fill-regular-dark;
  @include surface-bg-on-surface-alpha-dark;
  @include content-default-primary-dark;
}

.light .rs-tooltip .rs-tooltip-inner {
  @include border-fill-regular-light;
  @include surface-bg-on-surface-alpha-light;
  @include content-default-primary-light;
}

.dark .incomplete-user-info-modal .rs-tooltip-inner {
  @include surface-bg-on-surface-dark;
  @include border-fill-medium-dark;
  @include label-small-regular;
}

.light .incomplete-user-info-modal .rs-tooltip-inner {
  @include surface-bg-on-surface-light;
  @include border-fill-medium-light;
  @include label-small-regular;
}

.genius-picker .idui-popover__arrow {
  left: auto;
  right: calc(100% - 12px) !important;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
  border-radius: 4px;
}


.submenu-graphs .idui-popover__arrow {
    bottom: calc(100% - 12px);
    left: calc(100% - 289px) !important;
    -webkit-transform: translateX(-50%) rotate(135 deg);
    -ms-transform: translateX(-50%) rotate(135 deg);
    transform: translateX(-50%) rotate(135 deg);
    border-radius: 4px;
}

.idui-popover__content {
  max-height: -webkit-fit-content !important;
  max-height: -moz-fit-content !important;
  max-height: fit-content !important;
}

.Toastify__toast-theme--dark {
  -webkit-backdrop-filter: blur(25px);
  backdrop-filter: blur(25px);
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 16px !important;
  height: 480px;
  width: 370px;
}

.nps-beta-survey-container-expand {
  width: 370px !important;
  height: 480px !important;
}

.nps-beta-survey-container-collapsed {
  width: 230px !important;
  height: 50px !important;
}

.nps-beta-survey-container {
  -webkit-backdrop-filter: blur(25px);
  backdrop-filter: blur(25px);
  background-color: rgba($gray-550, 0.6);
  -webkit-transition: width 0.2s ease, height 0.2s ease, opacity 0.4s ease, -webkit-transform 0.3s ease;
  transition: width 0.2s ease, height 0.2s ease, opacity 0.4s ease, -webkit-transform 0.3s ease;
  -o-transition: transform 0.3s ease, width 0.2s ease, height 0.2s ease, opacity 0.4s ease;
  transition: transform 0.3s ease, width 0.2s ease, height 0.2s ease, opacity 0.4s ease;
  transition: transform 0.3s ease, width 0.2s ease, height 0.2s ease, opacity 0.4s ease, -webkit-transform 0.3s ease;
  opacity: 1;
}

.nps-beta-survey-container-hide {
  -webkit-transform: translateX(100%);
  -ms-transform: translateX(100%);
  transform: translateX(100%);
  opacity: 0;
}

.nps-beta-survey-circle-score {
  width: 15px;
  height: 15px;
}

.Toastify__toast-container--bottom-right {
  right: 6em !important;
}

.text-area-nps {
  background-color: transparent;
  border: 1px solid white;
}

.text-area-nps:focus {
  outline: none !important;
  border: 1px solid $green-500;
}

.form-check-input {
  background-color: transparent !important;
  border: 1px solid white !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.form-check-input:checked[type="radio"] {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

/*Loader dots */
.bouncing-loader {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.alert-container {
  overflow-y: auto;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: minmax(0, 1fr) 10px minmax(0, 1fr);
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 10px;
}

.bouncing-loader>div {
  width: 12px;
  height: 12px;
  margin: 2px 5px;
  border-radius: 50%;
  background-color: #a3a1a1;
  opacity: 1;
  -webkit-animation: bouncing-loader 0.6s infinite alternate;
  animation: bouncing-loader 0.6s infinite alternate;
}

@-webkit-keyframes bouncing-loader {
  to {
    opacity: 0.1;
    -webkit-transform: translateY(-12px);
    transform: translateY(-12px);
  }
}

@keyframes bouncing-loader {
  to {
    opacity: 0.1;
    -webkit-transform: translateY(-12px);
    transform: translateY(-12px);
  }
}

.bouncing-loader>div:nth-child(2) {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

.bouncing-loader>div:nth-child(3) {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

/* Notifications */

.rs-notification-item-content {
  background-color: transparent !important;
}

.rs-notification-title {
  color: $green-500 !important;
  font-family: "Sora" !important;
  font-size: 10pt !important;
  font-weight: 900 !important;
}

/* End Notifications */

/* Start Invoice */

.vertical-banner-invoice {
  top: 20%;
  transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform-origin: right bottom;
  -webkit-transform-origin: right bottom;
  -ms-transform-origin: right bottom;
}

.progress-circle-invoice {
  width: 90px;
  height: 90px;
  top: -20px;
  left: -10px;
}

.button-collapsed {
  background-color: $gray-800;
  color: $white;
  -webkit-transition-duration: 0.4s;
  -o-transition-duration: 0.4s;
  transition-duration: 0.4s;
}

.button-collapsed:hover {
  background-color: $green-500;
  color: $neutral-800;
}

.button-collapsed:disabled:hover {
  background-color: $gray-800;
  color: $white;
  opacity: 40%;
}

.text-one {
  position: absolute;
  left: 0;
  opacity: 1;
  -webkit-transition: opacity 0.5s, -webkit-transform 0.5s;
  transition: opacity 0.5s, -webkit-transform 0.5s;
  -o-transition: opacity 0.5s, transform 0.5s;
  transition: opacity 0.5s, transform 0.5s;
  transition: opacity 0.5s, transform 0.5s, -webkit-transform 0.5s;
}

.text-two {
  position: absolute;
  right: 0;
  -webkit-transition: right 0.5s;
  -o-transition: right 0.5s;
  transition: right 0.5s;
}

.slide-out-left {
  opacity: 0;
  -webkit-transform: translateX(-150%);
  -ms-transform: translateX(-150%);
  transform: translateX(-150%);
}

.slide-in-right {
  right: 30%;
}

.elevation-green {
  z-index: 3;
  -webkit-transform: scale(1.02);
  -ms-transform: scale(1.02);
  transform: scale(1.02);
  -webkit-transition: -webkit-transform 0.25s ease;
  transition: -webkit-transform 0.25s ease;
  -o-transition: transform 0.25s ease;
  transition: transform 0.25s ease;
  transition: transform 0.25s ease, -webkit-transform 0.25s ease;
  background: #2d2d2d !important;
  -webkit-box-shadow: 0 0 0 rgba(0, 0, 0, 0.25), inset 0 0 10px -5px $green-500, inset 0 0 10px -5px $green-500;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0.25), inset 0 0 10px -5px $green-500, inset 0 0 10px -5px $green-500;
}

.elevation-red {
  z-index: 3;
  -webkit-transform: scale(1.02);
  -ms-transform: scale(1.02);
  transform: scale(1.02);
  -webkit-transition: -webkit-transform 0.25s ease;
  transition: -webkit-transform 0.25s ease;
  -o-transition: transform 0.25s ease;
  transition: transform 0.25s ease;
  transition: transform 0.25s ease, -webkit-transform 0.25s ease;
  background: #2d2d2d !important;
  border-top: 0.5px solid rgba(252, 53, 53, 0.4);
  -webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), inset 0px -6px 15px -15px #fc3535, inset 0px 10px 15px -15px #fc3535;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), inset 0px -6px 15px -15px #fc3535, inset 0px 10px 15px -15px #fc3535;
}

.button-search {
  width: 27px;
  //height: 24px;
  -webkit-transition: width 0.25s;
  -o-transition: width 0.25s;
  transition: width 0.25s;
}

.account-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  gap: 10px;
}

.account-container>div {
  -ms-flex-preferred-size: 32%;
  flex-basis: 32%;
  position: relative;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.expand-button {
  width: 250px !important;
}

.search-input {
  background-color: $gray-800;
  border: 0;
}

.invoices-separator-line {
  height: 1px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

/* End Invoice */

.header {
  position: fixed;
  width: 100%;
}

.pulse {
  -webkit-animation: animate 1.5s linear infinite;
  animation: animate 1.5s linear infinite;
}

@-webkit-keyframes animate {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(206, 252, 52, 0.7), 0 0 0 0 rgba(206, 252, 52, 0.7);
    box-shadow: 0 0 0 0 rgba(206, 252, 52, 0.7), 0 0 0 0 rgba(206, 252, 52, 0.7);
  }

  100% {
    -webkit-box-shadow: 0 0 0 10px rgba(255, 109, 74, 0), 0 0 0 0 rgba(206, 252, 52, 0.7);
    box-shadow: 0 0 0 10px rgba(255, 109, 74, 0), 0 0 0 0 rgba(206, 252, 52, 0.7);
  }
}

@keyframes animate {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(206, 252, 52, 0.7), 0 0 0 0 rgba(206, 252, 52, 0.7);
    box-shadow: 0 0 0 0 rgba(206, 252, 52, 0.7), 0 0 0 0 rgba(206, 252, 52, 0.7);
  }

  100% {
    -webkit-box-shadow: 0 0 0 10px rgba(255, 109, 74, 0), 0 0 0 0 rgba(206, 252, 52, 0.7);
    box-shadow: 0 0 0 10px rgba(255, 109, 74, 0), 0 0 0 0 rgba(206, 252, 52, 0.7);
  }
}

.list-group-item {
  -webkit-backdrop-filter: contrast(0.75);
  backdrop-filter: contrast(0.75);  
  border: solid 0.5px transparent !important;
  transition: border 0.2s ease-in-out;
}

.list-group-item-action .icon-default {
  display: block;
}

.list-group-item-action .icon-hover {
  display: none;
}

.list-group-item-action.dark:not(.active):hover {
  border: 0.5px solid $alpha-white-10 !important;
}

.list-group-item-action.light:not(.active):hover {
  border: 0.5px solid $alpha-black-10 !important;
}

.list-group-item-action:not(.active):hover .icon-default {
  display: none;
}

.list-group-item-action:not(.active):hover .icon-hover {
  display: block;
}

.list-group-item:not(.list-group-item.active) {
  opacity: 0.5;
}

.eye-transition {
  -webkit-transition-property: all;
  -o-transition-property: all;
  transition-property: all;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -o-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-transition-duration: 400ms;
  -o-transition-duration: 400ms;
  transition-duration: 400ms;
}

.active-type-service {
  border: solid 0.5px $green-500;
  background-color: #212122;
}

.unactive-type-service {
  border: solid 0.5px #212122;
  background-color: #212122;
}

.disabled-type-service {
  background-color: #181819;
  border: solid 0.5px #181819;
}

.item-type-service {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -ms-flex-preferred-size: auto;
  flex-basis: auto;
  text-align: center;
  margin-right: 10px;
}

.item-type-service:last-child {
  margin-right: 0;
}

.button-circle-icon {
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.button-tour {
  min-width: 42px;
  height: 28px;
  background-color: #1f1f20;
  border: 0.5px solid rgba(255, 255, 255, 0.5);
  -webkit-backdrop-filter: blur(75px);
  backdrop-filter: blur(75px);
}

.container-tour {
  background: $black;
  border: solid 1px $gray-650;
}

.interactive-button-tour {
  border-radius: 24px;
  border: 0.5px solid rgba(255, 255, 255, 0.5);
  background: var(--neu-ref-gray-gray-50, #454545);
  -webkit-backdrop-filter: blur(75px);
  backdrop-filter: blur(75px);
}

.onboarding-modal-content {
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
}

.onboarding-modal-container {
  width: 400px;
  height: 650px;
  max-height: 90%;
  -webkit-backdrop-filter: blur(100px);
  backdrop-filter: blur(100px);
}

.onboarding-modal-dot {
  width: 4px;
  height: 4px;
}

.input-export-report {
  background-color: transparent;
  outline: none !important;
  border: none !important;
}

.input-export-report:focus {
  outline: none !important;
  border: none !important;
}

.buttons-snap-graph.dark {
  border-radius: 40px;
  border: 1px solid $alpha-white-8;
  backdrop-filter: blur(25px);
  -webkit-backdrop-filter: blur(10px);
  background-color: $alpha-black-4;
  -webkit-transition: opacity 0.3s ease;
  -o-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease;
}

.buttons-snap-graph.light {
  border-radius: 40px;
  border: 1px solid $alpha-black-8 ;
  backdrop-filter: blur(25px);
  -webkit-backdrop-filter: blur(10px);
  background-color: $alpha-white-4;
  -webkit-transition: opacity 0.3s ease;
  -o-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease;
}

.buttons-snap-graph.dark:hover {
  border: 0.5px solid $alpha-white-10;
  opacity: 1;
}

.buttons-snap-graph.light:hover {
  border: 0.5px solid $alpha-black-10;
  opacity: 1;
}

// input verification code

.cDRXnx {
  color: $gray-200 !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  font-size: 2rem !important;
}

.custom-verification-code {
  color: white !important;
  --ReactInputVerificationCode-itemWidth: 2.8rem;
  --ReactInputVerificationCode-itemHeight: 3rem;
  --ReactInputVerificationCode-itemSpacing: 0.3rem;
}

// end input verification code

.loadingChatbot {
  font-weight: bold;
  display: inline-block;
  -webkit-clip-path: inset(0 2ch 0 0);
  clip-path: inset(0 2ch 0 0);
  -webkit-animation: lchatbot 1s steps(3) infinite;
  animation: lchatbot 1s steps(3) infinite;
}

@-webkit-keyframes lchatbot {
  to {
    -webkit-clip-path: inset(0 -1ch 0 0);
    clip-path: inset(0 -1ch 0 0);
  }
}

@keyframes lchatbot {
  to {
    -webkit-clip-path: inset(0 -1ch 0 0);
    clip-path: inset(0 -1ch 0 0);
  }
}

.gradient-container-graph.dark {
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(#141414, 1)), to(rgba(#141414, 0)));
  background: -o-linear-gradient(top, rgba(#141414, 1), rgba(#141414, 0));
  background: linear-gradient(to bottom, rgba(#141414, 1), rgba(#141414, 0));
}

.gradient-container-graph.light {
  background: -webkit-gradient(linear, left top, left bottom, from(rgba($white, 1)), to(rgba($white, 0)));
  background: -o-linear-gradient(top, rgba($white, 1), rgba($white, 0));
  background: linear-gradient(to bottom, rgba($white, 1), rgba($white, 0));
}

.login-input-code {
  max-width: 30px;
  border: none !important
}

body.dark #invoices-table-scroll::-webkit-scrollbar-track:vertical {
  border-left: 1px solid $gray-800 !important;
}

body.light #invoices-table-scroll::-webkit-scrollbar-track:vertical {
  border-left: 1px solid $gray-200 !important;
}

body.dark #invoices-table-scroll::-webkit-scrollbar-track:horizontal {
  border-bottom: none;
  border-left: none;
  border-right: none;
  border-top: 1px solid $gray-800 !important;
}

body.light #invoices-table-scroll::-webkit-scrollbar-track:horizontal {
  border-bottom: none;
  border-left: none;
  border-right: none;
  border-top: 1px solid $gray-200 !important;
}

#invoices-table {
  border-collapse: separate;
  border-spacing: 0px 0.25rem;
  width: 100%;
}

#invoices-table.light tbody tr.invoice-row:hover td > div {
  background-color: $alpha-black-4;
}

#invoices-table.dark tbody tr.invoice-row:hover td > div {
  background-color: $alpha-white-6;
}

tbody tr td {
  padding: 4px 0;
}

.border-dashed {
  border-top: 1px dashed $gray-800;
}


.bg-gradient-lumi.dark{
 background: radial-gradient(100% 100% at 50% 0%, rgba(73, 148, 171, 0.20) 0%, rgba(73, 148, 171, 0.00) 100%), $gray-950;
 backdrop-filter: blur(10px);
}

.bg-gradient-lumi.light{
  background: radial-gradient(100% 100% at 50% 0%, rgba(73, 148, 171, 0.20) 0%, rgba(73, 148, 171, 0.00) 100%), $white;
  backdrop-filter: blur(10px);
 }

 .bg-questions-cards.dark{
  background: radial-gradient(94.85% 94.89% at 47.18% 5.15%, rgba(73, 148, 171, 0.30) 0%, rgba(0, 0, 0, 0.00) 100%), $alpha-black-8;
  backdrop-filter: blur(10px);
 }

 .bg-questions-cards.light{
  background: radial-gradient(94.85% 94.89% at 47.18% 5.15%, rgba(73, 148, 171, 0.30) 0%, rgba(0, 0, 0, 0.00) 100%), $alpha-black-8;
  backdrop-filter: blur(10px);
 }

 .questions-container {
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  cursor: grab;
  user-select: none;
  scroll-behavior: smooth;
  -ms-overflow-style: none; 
  scrollbar-width: none; 
}

.questions-text {
  white-space: normal; 
  overflow: hidden; 
  text-overflow: ellipsis; 
}

.bg-popover-none{
  background-color: transparent !important;
}

.embed-container {
  width: 100%;
  height: 100%;
  overflow: hidden !important;
  padding: 0px 0px 0px 25px;
  background-color: #000 !important;
  border-radius: 12px;
}

.embed-container iframe {
  clip-path: inset(0 25px 0 0);
}

/*
.embed-container {
  width:  100% !important;
  height: 100%;
}

.scroll-overlay {
  position: absolute;
  top: 0;
  right: 0;
  width: 18px;
  height: 100%;
  background: #000000;
  z-index: 20;
  pointer-events: none;
}*/

.gradient-container-header.dark {
  background: -webkit-gradient(linear, left top, left bottom, from(rgba($black, 1)), to(rgba($black, 0)));
  background: -o-linear-gradient(top, rgba($black, 1), rgba($black, 0));
  background: linear-gradient(to bottom, rgba($black, 1), rgba($black, 0));
}

.gradient-container-header.light {
  background: -webkit-gradient(linear, left top, left bottom, from(rgba($gray-100, 1)), to(rgba($gray-100, 0)));
  background: -o-linear-gradient(top, rgba($gray-100, 1), rgba($gray-100, 0));
  background: linear-gradient(to bottom, rgba($gray-100, 1), rgba($gray-100, 0));
}

.select-container > span {
  flex: 1;
  display: flex;
  align-items: stretch;
}

.select-container > span > div {
  flex: 1;
  display: flex;
  align-items: stretch;
}

.select-container > span > div > div {
  flex: 1;
  display: flex;
  align-items: stretch;
}

.select-container > span > div > div > button {
  flex: 1;
}

.incomplete-info-modal-input.dark::placeholder {
  color: map-get($palette, "alpha-white-64");
} 

.incomplete-info-modal-input.light::placeholder {
  color: map-get($palette, "alpha-black-64"); ;
} 