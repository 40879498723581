.hover-green.light {
    &:hover{
        background-color: $brand-primary-400;
    }
}

.hover-green.dark {
    &:hover{
        background-color: $brand-primary-200;
    }
}

.hover-gray.light {
    &:hover {
        background-color: $alpha-black-4;
    }
}

.hover-gray.dark {
    &:hover {
        background-color: $alpha-white-6;
    }
}