.object-fit-fill {
    object-fit: fill;
}

.object-fit-contain {
    object-fit: contain;
}

.object-fit-cover {
    object-fit: cover;
}

.object-fit-none {
    object-fit: none;
}

.object-fit-scale-down {
    object-fit: scale-down;
}