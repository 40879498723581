.bg-green-blue-gradient.dark {
  border: 1px solid transparent;
  background: linear-gradient($gray-950, $gray-950) padding-box, linear-gradient(90deg, $brand-primary-500 0%, $blue-500 100%) border-box;
}

.bg-green-blue-gradient.light {
  border: 1px solid transparent;
  background: linear-gradient($white, $white) padding-box, linear-gradient(90deg, $brand-primary-500 0%, $blue-500 100%) border-box;
}

.bg-green-blue-gradient-2 {
  border: 1px solid transparent;
  background: linear-gradient($gray-800, $gray-800) padding-box, linear-gradient(90deg, $green-500 0%, $blue-500 100%) border-box;
}

.bg-red-orange-gradient {
  background: $red-500;
  background: linear-gradient(90deg, $red-500 0%, $orange-500 100%);
}

.bg-purple-gradient {
  background: $purple-500;
  background: linear-gradient(90deg, $purple-500 0%, $purple-800 100%);
}

.bg-purple-pink-gradient {
  background: $purple-500;
  background: linear-gradient(110deg, $purple-500 10.99%, $pink-500 89.01%);
}

.bg-blue-gradient {
  background: $blue-500;
  background: linear-gradient(117.7deg, #006e7f 0.05%, #33c5dc 67.12%);
}

.bg-gray-gradient {
  background: linear-gradient(0deg, rgba(194, 194, 194, 0.5) 0%, rgba(194, 194, 194, 0.5) 100%), rgba(127, 127, 127, 0.2);
  background-blend-mode: overlay, luminosity;
}

.bg-black-transparent-gradient-horizontal.dark {
  background: $black;
  background: linear-gradient(90deg, $black 0%, rgba(0, 0, 0, 0) 100%);
}

.bg-black-transparent-gradient-horizontal.light {
  background: $gray-100;
  background: linear-gradient(90deg, $gray-100 0%, rgba(0, 0, 0, 0) 100%);
}

.bg-transparent-black-gradient-horizontal.dark {
  background: $black;
  background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, $black 100%);
}

.bg-light-transparent-gradient-horizontal {
  background: $gray-100;
  background: linear-gradient(90deg, $gray-100 0%, rgba(0, 0, 0, 0) 100%);
}

.bg-transparent-black-gradient-horizontal.light {
  background: $gray-100;
  background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, $gray-100 100%);
}

.bg-white-transparent-gradient-horizontal {
  background: $alpha-white-4; // Color base blanco
  background: linear-gradient(90deg, $alpha-white-4 0%, rgba(255, 255, 255, 0) 100%);
}

.bg-transparent-white-gradient-horizontal {
  background: $alpha-white-4; // Color base blanco
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, $alpha-white-4 100%);
}

.bg-black-transparent-gradient-vertical {
  background: $black;
  background: linear-gradient(0deg, $black 0%, rgba(0, 0, 0, 0) 100%);
}

.txt-blue-green-gradient {
  background: linear-gradient(90deg, $blue-600 0%, $brand-primary-500 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.txt-green-dark-gradient {
  background: linear-gradient(135deg, $brand-primary-500 30%, #cefc3460 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}


.txt-red-orange-gradient {
  background: linear-gradient(90deg, $red-500 0%, $orange-500 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.txt-purple-pink-gradient {
  background: linear-gradient(110deg, $purple-500 10.99%, $pink-500 89.01%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.txt-blue-gradient {
  background: linear-gradient(117.7deg, #006e7f 0.05%, #33c5dc 67.12%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.bg-gray-transparent-gradient-vertical {
  background: linear-gradient(0deg, $gray-500 0%, rgba($gray-500, 0) 100%);
}

.bg-gray-transparent-gradient-diagonal {
  background: linear-gradient(150deg, $gray-300 0%, rgba($gray-500, 0) 100%);
}

.bg-black-transparent-lumi.dark {
  background: $gray-950;
  background: linear-gradient(90deg, $gray-950 0%, rgba(0, 0, 0, 0) 100%);
}

.bg-black-transparent-lumi.light {
  background: $white;
  background: linear-gradient(90deg, $white 0%, rgba(0, 0, 0, 0) 100%);
}

.bg-transparent-black-lumi.dark {
  background: $gray-950;
  background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, $gray-950 100%);
}

.bg-transparent-black-lumi.light {
  background: $white;
  background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, $white 100%);
}

