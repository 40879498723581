@import "colors";
@import "fonts";
@import "texts";
@import "grid";
@import "pointers";
@import "zindex";
@import "borders";
@import "gradients";
@import "widths";
@import "heights";
@import "object-fit";
@import "backgrounds";
@import "absolute-position";
@import "opacities";
@import "animations";
@import "./font_styles/typography";
@import "./semantics/borders";
@import "./semantics/button";
@import "./semantics/content";
@import "./semantics/surface";
@import "./semantics/hovers";
@import "calendar";
@import "components";

@media (min-width: 768px) {
  ::-webkit-scrollbar {
    width: 18px;
    height: 20px;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  #modalInvoices::-webkit-scrollbar-track {
    background-color: white;
    border-bottom-right-radius: 24px;
  }

  .dark :not(.scroll-without-border)::-webkit-scrollbar-track:vertical {
    border-left: 1px solid $gray-800;
  }

  .light :not(.scroll-without-border)::-webkit-scrollbar-track:vertical, #modalInvoices::-webkit-scrollbar-track:vertical {
    border-left: 1px solid $gray-200;
  }

  .dark ::-webkit-scrollbar-track:horizontal {
    border-top: 1px solid $gray-800;
    border-bottom: 1px solid $gray-800;
    border-left: 1px solid $gray-800;
    border-right: 1px solid $gray-800;
  }

  .light ::-webkit-scrollbar-track:horizontal {
    border-top: 1px solid $gray-200;
    border-bottom: 1px solid $gray-200;
    border-left: 1px solid $gray-200;
    border-right: 1px solid $gray-200;
  }

  .dark ::-webkit-scrollbar-thumb {
    background-color: $gray-800;
    border-radius: 10px;
    border: 6px solid transparent;
    background-clip: content-box;
  }

  ::-webkit-scrollbar-corner {
    background-color: transparent;
  }

  .light ::-webkit-scrollbar-thumb, #modalInvoices::-webkit-scrollbar-thumb{
    background-color: $gray-300;
    border-radius: 10px;
    border: 6px solid transparent;
    background-clip: content-box;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: $gray-600;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  body {
    color: transparent !important;
  }

  /* Dark Mode */
  body.dark {
    background-color: #000;
  }

  /* Light Mode */
  body.light {
    background-color: #f5f5f5;
  }

  a {
    text-decoration: none !important;
  }

  input[type="number"] {
    -moz-appearance: textfield;
    appearance: textfield;
  }

  input:focus-visible {
    outline: 0;
  }

  .form-control:focus,
  .form-select:focus {
    border-color: $green-500 !important;
    box-shadow: none !important;
  }

  .form-select {
    background-image: url("data: image/svg+xml, <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' ><path fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6' /></svg>");
  }

  .form-floating > .form-control:focus ~ label::after,
  .form-floating > .form-control:not(:placeholder-shown) ~ label::after,
  .form-floating > .form-control-plaintext ~ label::after,
  .form-floating > .form-select ~ label::after {
    background-color: transparent;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-background-clip: text;
    -webkit-text-fill-color: #ffffff;
    transition: background-color 5000s ease-in-out 0s;
    box-shadow: inset 0 0 20px 20px #23232329;
  }

  .border-none-scroll::-webkit-scrollbar-track {
    border: none;
  }
}

:root {
  --font-sora: "Sora", sans-serif;
  --font-inter: "Inter", sans-serif;
  --font-space-grotesk: "SpaceGrotesk", sans-serif;
}

.rs-loader-spin::after {
  border: 3px solid transparent !important;
  -webkit-animation: loaderSpin 0.6s linear infinite;
  animation: loaderSpin 0.6s linear infinite;

  .dark & {
    border-top-color: $gray-400 !important;
  }

  .light & {
    border: 3px solid $gray-200 !important;
    border-top-color: $gray-300 !important;
  }
}

.invoice-state-arrow {
  display: block;
  height: 20px;
  width: 20px;
  position: absolute;
  top: -5px;
  left: calc(50% - 10px);
  -webkit-clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
  clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
  -webkit-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  transform: rotate(135deg);
  border-radius: 0 0 0 0.25em;
}

.month-picker .rdrMonths,
.month-picker .rdrDateDisplayWrapper {
  display: none;
}

.rs-modal-dialog {
  width: 100% !important;
}
.tr:nth-child(odd) { background-color: #f2f2f3;}
.tr:nth-child(even) {background-color: #ffffff;}
