.pe-alias {
    cursor: alias !important;
}

.pe-all-scroll {
    cursor: all-scroll !important;
}

.pe-auto {
    cursor: auto !important;
}

.pe-cell {
    cursor: cell !important;
}

.pe-context-menu {
    cursor: context-menu !important;
}

.pe-col-resize {
    cursor: col-resize !important;
}

.pe-copy {
    cursor: copy !important;
}

.pe-crosshair {
    cursor: crosshair !important;
}

.pe-default {
    cursor: default !important;
}

.pe-e-resize {
    cursor: e-resize !important;
}

.pe-ew-resize {
    cursor: ew-resize !important;
}

.pe-grab {
    cursor: grab !important;
}

.pe-grabbing {
    cursor: grabbing !important;
}

.pe-help {
    cursor: help !important;
}

.pe-move {
    cursor: move !important;
}

.pe-n-resize {
    cursor: n-resize !important;
}

.pe-ne-resize {
    cursor: ne-resize !important;
}

.pe-nesw-resize {
    cursor: nesw-resize !important;
}

.pe-ns-resize {
    cursor: ns-resize !important;
}

.pe-nw-resize {
    cursor: nw-resize !important;
}

.pe-nwse-resize {
    cursor: nwse-resize !important;
}

.pe-no-drop {
    cursor: no-drop !important;
}

.pe-none {
    cursor: none !important;
}

.pe-not-allowed {
    cursor: not-allowed !important;
}

.pe-pointer {
    cursor: pointer !important;
}

.pe-progress {
    cursor: progress !important;
}

.pe-row-resize {
    cursor: row-resize !important;
}

.pe-s-resize {
    cursor: s-resize !important;
}

.pe-se-resize {
    cursor: se-resize !important;
}

.pe-sw-resize {
    cursor: sw-resize !important;
}

.pe-text {
    cursor: text !important;
}

.pe-w-resize {
    cursor: w-resize !important;
}

.pe-wait {
    cursor: wait !important;
}

.pe-zoom-in {
    cursor: zoom-in !important;
}

.pe-zoom-out {
    cursor: zoom-out !important;
}